import React, { Suspense } from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import lazy from "react-lazy-with-preload";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const StatsPage = lazy(() => import('./pages/StatsPage'));
const UploadPage = lazy(() => import('./pages/UploadPage'));
const TextEditorPage = lazy(() => import('./pages/TextEditorPage'));
const HomePage = lazy(() => import('./pages/HomePage'));
const SideBar = lazy(() => import('./components/SideBar'));



global.DEV = false
if (process.env.NODE_ENV === 'development') {
  console.log('Running in development mode');
  global.DEV = true;
} else if (process.env.NODE_ENV === 'production') {
  console.log('Running in production mode');
} else {
  console.log('Unknown environment');
}

global.HOST = global.DEV ? 'http://localhost:8080' : 'http://143.198.58.92:8080'
global.FRONTEND_HOST = global.DEV ? 'http://localhost:3000' : 'http://143.198.58.92:8080'




function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>


      <div className="App">
        <BrowserRouter>
        <SideBar />

          <Suspense fallback={<div style={{ height: '100vh', overflowY: 'hidden', width: '100%',/* backgroundColor: '#2d98e0'*/ }}><img src="" style={{ filter: 'hue-rotate(212deg)', borderRadius: '50%', objectFit: 'cover', width: '100%', height: '100%', transform: 'scale(0.5)', maxWidth: '800px', margin: 'auto' }} /></div>}>

            <div className="pages">

              <Routes>
                <Route exact path="/" element={<UploadPage />} />
                <Route exact path="/profile/:id" element={<StatsPage />} />
                <Route exact path="/editor/:id" element={<TextEditorPage />} />

              </Routes>

            </div>

          </ Suspense >
        </BrowserRouter>
      </div>
    </LocalizationProvider>

  );
}

export default App;
